<script setup>
import { mdiChevronDown } from '@mdi/js'

import { useDisplay } from 'vuetify/lib/framework.mjs'

useServerSeoMeta({
  title: '', // purposefully left blank so title template in app.vue works as expected
  description: `Experience the heart of God through biblical preaching and vibrant worship that blends tradition and modern praise. We are a church rooted in the reformed tradition and desire to glorify God and love each other in all we do.`,
  ogTitle: 'Pathway Bible Church',
  ogDescription: `Experience the heart of God through biblical preaching and vibrant worship that blends tradition and modern praise. We are a church rooted in the reformed tradition and desire to glorify God and love each other in all we do.`,
  ogType: 'website',
  ogUrl: 'https://pathwaybiblechurch.ca',
  ogImage: 'https://pathwaybiblechurch.ca/img/share.jpg',
  ogLocale: 'en_US',
  colorScheme: 'only light',
  robots: 'index follow',
})

useJsonld({
  '@context': 'http://schema.org',
  '@type': 'Church',
  'url': 'https://pathwaybiblechurch.ca',
  'name': 'Pathway Bible Church',
  'openingHours': 'Su 10:00-12:00',
  'address': {
    '@type': 'PostalAddress',
    'addressLocality': 'St. Catharines',
    'addressRegion': 'ON',
    'postalCode': 'L2S2Z3',
    'streetAddress': '1136 Vansickle Road N.',
  },
  'geo': {
    '@type': 'GeoCoordinates',
    'latitude': '43.16',
    'longitude': '-79.27',
  },
  'isAccessibleForFree': true,
  'openingHoursSpecification': [
    {
      '@type': 'OpeningHoursSpecification',
      'closes': '12:00:00',
      'dayOfWeek': 'http://schema.org/Sunday',
      'opens': '10:00:00',
    },
  ],
  'photo': 'http://www.pathwaybiblechurch.ca/img/share.jpg',
  'image': 'http://www.pathwaybiblechurch.ca/img/share.jpg',
  'logo': 'http://www.pathwaybiblechurch.ca/img/logo.png',
  'publicAccess': true,
  'smokingAllowed': false,
  'telephone': '905-685-1441',
  'description': 'Experience the heart of God through biblical preaching and vibrant worship that blends tradition and modern praise. We are a church rooted in the reformed tradition and desire to glorify God and love each other in all we do.',
})

const { mdAndUp, smAndUp } = useDisplay()
</script>

<template>
  <div>
    <div class="hero">
      <NuxtImg
        src="/img/homepage/hero.jpg"
        alt="A Path"
        style="width:100%; height: 100%; object-fit: cover"
        fit="cover"
        format="webp"
        sizes="xl:100vw lg:100vw md:100vw sm:100vw xs:100vw"
        width="3000"
        height="1686"
        quality="60"
        preload
        fetchpriority="high"
      />
      <div class="hero-content ">
        <div style="height: 100%">
          <div class="d-flex h-100 align-center">
            <v-row justify="center">
              <v-col cols="auto" style="max-width: min(100%, 650px)">
                <div class="d-flex flex-column justify-center align-center text-white text-center px-4 mb-10 pb-10 animated">
                  <div class="d-flex" style="font-size: 3.5rem; font-weight: 600; line-height: 1.25;">
                    <div data-aos="zoom-in-up" data-aos-delay="250">
                      Your
                    </div>
                    &nbsp;
                    <div data-aos="zoom-in-up" data-aos-delay="350">
                      word
                    </div>
                  </div>

                  <div style="font-size: 2rem;  line-height: 1.75;">
                    <span data-aos="zoom-in-up" data-aos-delay="900">
                      is&nbsp;
                    </span>
                    <span data-aos="zoom-in-up" data-aos-delay="1000">
                      a&nbsp;
                    </span>
                    <span data-aos="zoom-in-up" data-aos-delay="1100">
                      <span style="font-size: 2.5rem; font-weight: 600;">lamp&nbsp;</span>
                    </span>
                    <span data-aos="zoom-in-up" data-aos-delay="1200">
                      to&nbsp;
                    </span>
                    <span data-aos="zoom-in-up" data-aos-delay="1300">
                      my&nbsp;
                    </span>
                    <span data-aos="zoom-in-up" data-aos-delay="1400">
                      <span style="font-size: 2.5rem; font-weight: 600;">feet</span>,
                    </span>
                  </div>

                  <div style="font-size: 2rem;  line-height: 1.75;" class="mb-3">
                    <span data-aos="zoom-in-up" data-aos-delay="1950">
                      a&nbsp;
                    </span>
                    <span data-aos="zoom-in-up" data-aos-delay="2050">
                      <span style="font-size: 2.5rem; font-weight: 600;">light</span>&nbsp;
                    </span>
                    <span data-aos="zoom-in-up" data-aos-delay="2150">
                      for&nbsp;
                    </span>
                    <span data-aos="zoom-in-up" data-aos-delay="2250">
                      my&nbsp;
                    </span>
                    <span data-aos="zoom-in-up" data-aos-delay="2250">
                      <span style="font-size: 2.5rem; font-weight: 600;">path</span>.
                    </span>
                  </div>
                  <span data-aos="zoom-in" data-aos-delay="2800">
                    <div class="text-overline" style="line-height: 1.75;">
                      Psalm 119:105
                    </div>
                  </span>

                  <v-row justify="center" class="mt-5">
                    <v-col cols="auto">
                      <div data-aos="fade-right" data-aos-delay="2800">
                        <v-btn to="/faith/what-is-the-gospel" color="primary" rounded size="large" style="width: 240px;">
                          What is the Gospel?
                        </v-btn>
                      </div>
                    </v-col>
                    <v-col cols="auto">
                      <div data-aos="fade-left" data-aos-delay="2800">
                        <v-btn to="/about/what-we-believe" color="secondary" density="default" rounded size="large" style="width: 240px;">
                          About Us
                        </v-btn>
                      </div>
                    </v-col>
                  </v-row>
                </div>
              </v-col>
            </v-row>
          </div>
          <div class="scroll-arrow">
            <v-btn :to="{ path: '/', hash: '#welcome' }" :icon="mdiChevronDown" :active="false" variant="text" size="xlarge" aria-label="Scroll Down" />
          </div>
        </div>
      </div>
    </div>

    <!-- Welcome / Stream -->
    <section id="welcome" style="padding-top: 30px; padding-bottom: 60px">
      <v-container>
        <v-row
          align="center"
          justify="space-around"
        >
          <v-col
            lg="5"
            md="6"
            sm="12"
            cols="12"
          >
            <div :data-aos="smAndUp ? 'fade-right' : 'zoom-in'" :data-aos-delay="smAndUp ? 250 : 0">
              <div class="vision">
                <div class="text-overline text-primary">
                  Our Vision
                </div>
                <div style="line-height: 1.25;">
                  To See Jesus and Together Become More Like Him
                </div>
              </div>

              <div class="mission text-center mt-8 mb-10">
                <div class="text-overline text-primary">
                  Our Mission
                </div>
                <div><span class="text-h6">E</span>ncourage members to follow Jesus</div>
                <div><span class="text-h6">E</span>quip followers to serve Jesus</div>
                <div><span class="text-h6">E</span>ngage each other and together be the body</div>
              </div>

              <v-row align="center" justify="center">
                <v-col cols="auto">
                  <v-btn color="primary" to="/about/our-story">
                    Our Story
                  </v-btn>
                </v-col>
                <v-col cols="auto">
                  <v-btn color="primary" to="/about/what-we-believe">
                    What We Believe
                  </v-btn>
                </v-col>
              </v-row>
            </div>
          </v-col>
          <v-col
            lg="5"
            md="6"
            sm="8"
            cols="12"
          >
            <div :data-aos="smAndUp ? 'fade-left' : 'zoom-in'" data-aos-delay="250">
              <v-responsive :aspect-ratio="16 / 9">
                <LiveStream />
              </v-responsive>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </section>

    <!-- Join Us -->
    <div id="join-us" />
    <div data-aos="fade-in">
      <section class="joinus bg-primary">
        <v-container>
          <v-row justify="center">
            <v-col
              xl="5"
              md="6"
              sm="8"
              cols="12"
              order="2"
              order-md="1"
            >
              <div data-aos="zoom-in" data-aos-delay="250">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d514.4738513983808!2d-79.27212488110904!3d43.161151145503716!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89d351977a856fc1%3A0x4ad046a4c2f9229d!2sFirst%20Reformed%20Church%20of%20St.%20Catharines!5e0!3m2!1sen!2sca!4v1669255333090!5m2!1sen!2sca"
                  style="width: 100%; height: auto; min-height: 350px"
                  class="map"
                  title="Map Showing Pathway Bible Church"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                />
              </div>
            </v-col>

            <v-col
              xl="7"
              md="6"
              cols="12"
              style="align-self: center"
              order-md="2"
              order="1"
            >
              <div class="heading">
                <div class="mb-3">
                  Join us <br> for worship
                </div>

                <div class="text-overline mb-3">
                  Sundays at 10 AM
                </div>
              </div>

              <address class="mb-4 text-center">
                1136 Vansickle Road North <br>
                St. Catharines, ON <br>
              </address>

              <v-row justify="center">
                <v-col cols="auto">
                  <v-btn to="/about/plan-your-visit">
                    Plan your Visit
                  </v-btn>
                </v-col>
                <v-col cols="auto">
                  <v-btn
                    href="https://www.google.com/maps/dir//First+Reformed+Church+of+St.+Catharines,+1136+Vansickle+Rd+N,+St.+Catharines,+ON+L2S+2Z3/@43.1611823,-79.2736369,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x89d351977a856fc1:0x4ad046a4c2f9229d!2m2!1d-79.271386!2d43.1611995"
                    target="_blank"
                    rel="noopener"
                  >
                    Directions
                  </v-btn>
                </v-col>
                <!-- <v-col cols="auto">
                <v-btn>
                  Request a Ride
                </v-btn>
              </v-col> -->
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </section>
    </div>

    <!-- Get Involved -->
    <section class="getinvolved">
      <v-container>
        <v-row
          align="center"
          justify-sm="center"
          justify-md="space-between"
        >
          <v-col
            cols="12"
            sm="8"
            md="6"
            class="py-6"
          >
            <div :data-aos="mdAndUp ? 'fade-right' : 'zoom-in'">
              <div class="heading mb-5">
                Stay Connected
              </div>

              <p class="text-center pb-3">
                Sign up for our newsletter to receive updates on events, announcements, and opportunities to get involved—all delivered to your inbox.
              </p>

              <NewsletterSignup />
            </div>
          </v-col>
          <v-col
            cols="12"
            md="5"
          >
            <div :data-aos="mdAndUp ? 'fade-left' : 'zoom-in'">
              <EventList />
            </div>
          </v-col>
        </v-row>
      </v-container>
    </section>

    <section class="pastors">
      <v-container>
        <div data-aos="fade-up">
          <div class="heading mb-5">
            Meet the Pastors
          </div>
        </div>

        <div data-aos="fade-up">
          <v-card
            class="py-5 mb-5"
            variant="flat"
          >
            <v-row>
              <v-col
                cols="12"
                md="auto"
                align="center"
              >
                <v-avatar
                  image="/img/homepage/PastorArt.jpg"
                  size="160"
                />
              </v-col>
              <v-col
                cols="12"
                md
              >
                <div class="text-h6 pb-2">
                  Pastor Art VanSlageren
                </div>
                <div>
                  Pastor Art is the senior pastor of Pathway Bible Church. He and his wife Allison have served here since July 1992. They have three adult children, Aaron, BethAnn and Bradley who also worship and serve at PBC. Art received his bachelor’s degree from Reformed Bible College and his masters from Western Theological Seminary. His love for God draws him daily to the Word. His gratitude for saving faith in Jesus Christ motivates his ministry at PBC and beyond. And he acknowledges daily the work of the Holy Spirit to refresh him. Along with a love for study of God’s Word and preaching the good news of Jesus, Art has the joy of teaching Senior and Adult Bile study, leading a Men’s discipleship class and a new members class. Art also enjoys the blessing of leading worship with gifted musicians on Sunday mornings. In his spare time, he enjoys spending time with his family, golf and is a Classic car enthusiast.
                </div>
              </v-col>
            </v-row>
          </v-card>
        </div>

        <div data-aos="fade-up">
          <v-card
            class="py-5"
            variant="flat"
          >
            <v-row>
              <v-col
                cols="12"
                md="auto"
                align="center"
              >
                <v-avatar
                  image="/img/homepage/PastorQuinton.jpg"
                  size="160"
                />
              </v-col>
              <v-col
                cols="12"
                md
              >
                <div class="text-h6 pb-2">
                  Pastor Quinton Mol
                </div>
                <div>
                  Pastor Quinton has been our Associate Pastor since 2019. He did his undergrad studies at Redeemer University and received his Masters of Divinity from Western Theological Seminary.
                  He is passionate about the Word of God, and loves seeing faith be manifested in every area of life. When he is not at home with his wife and children, he loves spending time outdoors either gardening, fishing or hunting.
                </div>
              </v-col>
            </v-row>
          </v-card>
        </div>
      </v-container>
    </section>
  </div>
</template>

<style scoped lang="scss">
  :deep(.hero) {
    position: relative;
    height: min(calc(100vh - 80px), 200vw);

    .hero-content {
      position: absolute;
      left:0;
      right: 0;
      top: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.6);
    }

    .animated {
      span {
        display: inline-block;
      }
    }
  }

  .scroll-arrow {
    position: absolute;
    bottom: 40px;
    width: 100%;
    text-align: center;
    color: white;
    animation: bounce 2s infinite;

    .v-btn {
      font-size: 2.5rem;
    }
  }
  @keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-10px);
    }
    60% {
      transform: translateY(-5px);
    }
  }

  .vision {
    font-size: max(min(3vw, 30px), 30px);
    line-height: 1;
    text-align: center;
  }

  .joinus {
    color: #fff;
    padding: 30px 0px;
    font-size: 16px;
    .text-overline {
      font-size:18px !important;
    }
    .heading {
      font-size: max(min(5vw, 40px), 30px);
      font-weight: bold;
      text-transform: uppercase;
      line-height: 1;
      text-align: center;
    }
    .map {
      border: 0;
      width: 100%;
      min-height: 100%;
    }
  }

  .getinvolved {
    padding: 50px 0px;
    .heading {
      font-size: max(min(5vw, 40px), 30px);
      font-weight: bold;
      line-height: 1;
      text-align: center;
    }
  }

  .pastors {
    padding-bottom: 50px;
    .heading {
      font-size: max(min(2vw, 40px), 30px);
      font-weight: bold;
      line-height: 1;
      text-align: center;
    }
  }
</style>
